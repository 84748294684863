import styled from 'styled-components';

export const StyledContent = styled.article`
  text-align: justify;
  font-size: ${({ theme }) => theme.font.size.ms};
  font-family: 'Montserrat', sans-serif;

  ${({ theme }) => theme.mq.tablet} {
    font-size: ${({ theme }) => theme.font.size.mm};
  }

  ${({ theme }) => theme.mq.desktopMedium} {
    font-size: ${({ theme }) => theme.font.size.mmm};
  }
`;
export const StyledWrapper = styled.div`
  width: ${({ theme }) => theme.widthValue.xxl};
  margin: 0 auto 50px auto;

  ${({ theme }) => theme.mq.desktopSmall} {
    width: ${({ theme }) => theme.widthValue.xl};
  }

  ${({ theme }) => theme.mq.desktopMedium} {
    width: ${({ theme }) => theme.widthValue.l};
  }

  ${({ theme }) => theme.mq.desktopBig} {
    grid-column-gap: 35px;
    grid-template-columns: 610px 1fr;
    width: ${({ theme }) => theme.widthValue.m};
  }

  ${({ theme }) => theme.mq.desktopHuge} {
    width: ${({ theme }) => theme.widthValue.s};
  }
`;

export const StyledHeading = styled.h1`
  margin: 3rem auto 3rem auto;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: ${({ theme }) => theme.font.size.l};
  text-transform: uppercase;
  letter-spacing: 0.6rem;
  font-weight: 100;

  ${({ theme }) => theme.mq.tablet} {
    margin: 7rem auto 5rem auto;
    font-size: ${({ theme }) => theme.font.size.xl};
  }

  ${({ theme }) => theme.mq.desktopMedium} {
    font-size: ${({ theme }) => theme.font.size.xxl};
    margin: 7rem auto;
  }
`;
