import React from 'react';
import MediaQuery from 'react-responsive';
import SEO from '../components/SEO/SEO';
import Header from '../components/Header/Header';
import MainTemplate from '../templates/MainTemplate';
import PrivacyPolicyTemplate from '../templates/PrivacyPolicyTemplate';
import FooterTemplate from '../templates/FooterTemplate';
import NavButton from '../components/NavButton';

const PrivacyPolicyPage = () => {
  return (
    <MainTemplate>
      <SEO title="Polityka prywatności" />
      <Header hashPart={false} />
      <PrivacyPolicyTemplate />
      <FooterTemplate iconSize={42} />
      <MediaQuery maxWidth={1020}>
        <NavButton />
      </MediaQuery>
    </MainTemplate>
  );
};

export default PrivacyPolicyPage;
