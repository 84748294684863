import React from 'react';
import {
  StyledHeading,
  StyledWrapper,
  StyledContent,
} from './PrivacyPolicyTemplate.style';

const PrivacyPolicyTemplate = () => {
  return (
    <>
      <StyledHeading style={{ margin: '150px 0 50px 0' }}>
        Polityka prywatności
      </StyledHeading>
      <StyledWrapper>
        <StyledContent>
          <p>
            Administrator serwisu dokłada wszelkich starań, aby zapewnić
            bezpieczeństwo danych osobowych.
          </p>
          <p>
            Informacje uzyskane za pośrednictwem witryny, wykorzystywane są do
            realizacji świadczonych usług, jak również w celach
            administracyjnych i statystycznych.
          </p>
          <p>
            Podanie przez użytkownika danych osobowych jest dobrowolne.
            Niepodanie danych osobowych może w niektórych przypadkach
            uniemożliwić kontakt lub zrealizowanie usługi.
          </p>
          <p>
            Informacje zgromadzone za pomocą formularzy nie są w żaden sposób
            udostępniane podmiotom trzecim, chyba że wynika to ze specyfiki
            świadczonych usług i jest niezbędne do prawidłowego ich
            zrealizowania.
          </p>
          <p>
            Użytkownik serwisu ma prawo dostępu do danych osobowych oraz ich
            poprawiania. W tym celu konieczne jest skontaktowanie się z
            administratorem serwisu za pomocą formularza kontaktowego.
          </p>
          <p>
            Serwis może zawierać odnośniki do innych stron internetowych.
            Witryny te działają niezależnie i nie są w żaden sposób nadzorowane
            przez administratora serwisu. Mogą również posiadać własne polityki
            dotyczące prywatności, z którymi należy się zapoznać. W przypadku
            zmiany polityki prywatności obowiązującej w serwisie, jej
            aktualizacja zostanie zamieszczona na niniejszej podstronie.
          </p>
        </StyledContent>
      </StyledWrapper>
    </>
  );
};

export default PrivacyPolicyTemplate;
